<template>
    <div class="screenContainer">
        <img src="../../../assets/image/zhiboBack.png" class="backImg"/>

        <!-- <div class="time">
            Remaining time {{itemData.countDownTimes?itemData.countDownTimes:getFormatDuringTime(0)}}
        </div> -->

        <Invigilate
            v-model="status"
            :template_id="template_id"
            @invigilateCallback="invigilateCallback"
            @visibleChange="refreshVisible"
            class="invigilateStyle"

        ></Invigilate>

        <video v-if="!showYoutubeVideo" :src="videoUrl" id="video" @canplay="doRequestVideoPlayTime" controls="false" controlsList="nodownload noplaybackrate" disablePictureInPicture class="videoClass"></video>
      <div v-else  class="youtubeClass">
        <YoutubeVideo :video-url="videoUrl" ref="youtubeContainer"/>
      </div>
        <!-- <video :src="videoUrl" id="video" @canplay="doRequestVideoPlayTime" controls="false" controlsList="nodownload noplaybackrate" disablePictureInPicture class="videoClass"></video> -->

        <!-- <div class="close" @click="close"></div> -->
        <!-- <div class="screenContent">
            <div class="screenLeft">
                <div id="main"></div>
            </div>
            <div class="screenRight">
                <div class="chatBoard">
                    <div v-for="(item,index) in messageContainer" :key="index" class="messageItem">
                        <div class="messageUser">{{userNamesMap[item.userId]['name']}}：<span class="messageContent">{{item.data}}</span></div>
                    </div>
                </div>
                <div class="input">
                    <el-input type="textarea" v-model="editMessage" placeholder="Please input the message and press enter to send it" resize="none" @keydown.enter.native="sendMessage"></el-input>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { getResidueTime,saveVideoRecordPlayTime,getVideoRecordTime ,getLectureTip} from '../../../api/arena'
import { getUserId,getProjectCode ,getSessionUrl ,getSessionName} from '../../../utils/store'

import Invigilate from "../components/Invigilate.vue";
import YoutubeVideo from '../components/YoutubeVideo.vue';

export default {
  name:'VideoPlay',
  components: {
    Invigilate,
    YoutubeVideo
  },
  watch: {
    videoUrl:{
      handler(newVal, oldVal) {
        this.checkVideoUrl()
      },
      immediate: true
    },
    status(newStatus, oldStatus) {


      if (this.invigilateStatus){
        this.videoUrl = getSessionUrl()
      }else{
        if (!newStatus) {
          //禁止做题目了
          console.log("---sorry--");
          this.canExam = false;
        //   this.clearCountDown();
          this.videoUrl = ''
          this.closeVideo()
          //停止一切
        } else {
          console.log("---yes --");
          this.canExam = true;
          this.videoUrl = getSessionUrl()
        }
      }

    },
  },
    data () {
        return {
            showYoutubeVideo: false,
            audioDevice: '',
            videoDevice: '',
            audioDevices: [],
            videoDevices: [],
            roomToken: '',
            userList: [],
            roomId:'',
            messageContainer:[],
            editMessage:'',
            userNamesMap:{},
            roomId:this.$route.query.roomId,
            event_name:getSessionName(),
            videoTime:this.$route.query.videoTime,
            template_id:this.$route.query.template_id,
            videoUrl:'',
            itemData:{},
            status: false,
            cameraOptions: {
                audio: false,
                video: { width: 500, height: 500 },
            },
            screenOptions: {
                audio: false,
                video: { width: 1280, height: 720 },
            },
            timer: "",

            device_camera_status: true,
            device_screen_status: true,
            cameraVideo: null, // 摄像头视频容器
            screenVideo: null, // 屏幕视频容器
            cameraStream: null, // 摄像头视频流
            screenStream: null, // 屏幕视频流

            idCanvas: undefined,
            idVideo: undefined,
            idContext: undefined,
            idVideoWidth: 400,
            idVideoHeight: 300,
            idCheckSuccess: false,
            idStream: null,
            idVideoStream: null,
            showIdPicDialog: false,
            pic_url: "",
            videoStartTime: 0,
            videoPlayRecordTimer:undefined,
          isInitPlayTime: false
        }
    },
    mounted () {
        document.title = 'Lecture'
        // console.log(111111,getSessionUrl())
        // this.videoUrl = getSessionUrl()
        this.event_name = getSessionName()
        // this.getUserNames()
        // this.joinRoom()

        // this.checkDevice();

        // this.getResidueTime()
        // this.doRequestVideoPlayTime()
        this.getLectureTip()

    //   this.videoUrl = getSessionUrl()
    },
    beforeDestroy() {

    if(this.videoPlayRecordTimer){
      clearInterval(this.videoPlayRecordTimer)
    }
        console.log("---beforeDestroy---");
        if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        }
        this.clearAllResource();
    },
    methods: {

        checkVideoUrl(){
            console.log('url ->'+ this.videoUrl)
        // this.videoUrl = "https://www.youtube.com/watch?v=y1plpKbdCLg"
          if(this.videoUrl.indexOf("youtube")>=0){
            //you tube video
            this.showYoutubeVideo=true
            let that = this
            setTimeout(()=>{
              that.doRequestVideoPlayTime()
            },300)
          }
      },
        getLectureTip(){

            getLectureTip(getUserId(),getProjectCode()).then((res)=>{
                
                if (res.data != ''){

                    this.$alert(res.data, 'Tip', {
                        confirmButtonText: 'confirm',
                        callback: action => {
                            
                        }
                    });
                }
                
            })
            
        },

        doSaveVideoPlayTime(){
          if(this.videoPlayRecordTimer){
            clearInterval(this.videoPlayRecordTimer)
          }
          let that = this
          let isYoutube  = this.showYoutubeVideo
          this.videoPlayRecordTimer = setInterval(()=>{
            if(isYoutube){
              that.$refs['youtubeContainer'].getPlayer().getCurrentTime().then((time)=>{
                if(time && time>0){
                    saveVideoRecordPlayTime(getUserId(),this.videoUrl,getProjectCode(),time).then((res)=>{
                  })
                }
              })
            }else {
              let video = document.getElementById('video')
             let  currentTime = video.currentTime
              if(currentTime && currentTime>0){
                saveVideoRecordPlayTime(getUserId(),this.videoUrl,getProjectCode(),currentTime).then((res)=>{
                })
              }
            }
            // let video = document.getElementById('video')
            // let currentTime = video.currentTime
            // if(currentTime>0){
            //     saveVideoRecordPlayTime(getUserId(),this.videoUrl,getProjectCode(),currentTime).then((res)=>{
            //   })
            // }
          },2000)
        },
        doRequestVideoPlayTime(){
          if(this.isInitPlayTime){
            return
          }
          this.isInitPlayTime = true
          getVideoRecordTime(getUserId(),this.videoUrl,getProjectCode()).then((res)=>{
              let result = res.data
              if(result != null) {
                this.videoStartTime = parseInt(result.watch_to_time)
                let isYoutube  = this.showYoutubeVideo
                if(isYoutube){
                  this.$refs['youtubeContainer'].getPlayer().seekTo(this.videoStartTime)
                }else {
                  let video = document.getElementById('video')
                  video.currentTime = this.videoStartTime
                }
              }
            this.doSaveVideoPlayTime()
          })
        },


        async checkDevice() {
            if (this.status) {
                return;
            }
            this.clearAllResource();
            // 判断浏览器是否有权限
            if (
                !navigator ||
                !navigator.mediaDevices ||
                !navigator.mediaDevices.getUserMedia
            ) {
                this.$message.error("Your browser does not support camera sharing.");
                return console.log("浏览器不支持访问摄像头!");
            }
            if (
                !navigator ||
                !navigator.mediaDevices ||
                !navigator.mediaDevices.getDisplayMedia
            ) {
                this.$message.error("Your browser does not support screen sharing");
                return console.log("浏览器不支持屏幕录制!");
            }
            this.cameraVideo = document.createElement("video");
            this.screenVideo = document.createElement("video");
            // 视频流
            const loading = this.$loading({
                lock: true,
                text: "Getting camera authorization..",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let stream = await navigator.mediaDevices
                .getUserMedia(this.cameraOptions)
                .catch((err) => {
                this.$message.error(` error:${err}`);
                this.device_camera_status = false;
                this.clearAllResource();
                return;
                })
                .finally(() => {
                loading.close();
                });
            this.cameraStream = stream;
            this.cameraVideo.srcObject = stream;
            this.cameraVideo.play();
            loading.close();
            // 屏幕流
            const loading1 = this.$loading({
                lock: true,
                text: "Getting screen authorization...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let stream1 = await navigator.mediaDevices
                .getDisplayMedia(this.screenOptions)
                .catch((err) => {
                this.$message.error(` error:${err}`);
                this.device_screen_status = false;
                this.clearAllResource();
                return;
                })
                .finally(() => {
                loading1.close();
                });
            this.device_camera_status = true;
            if (
                stream1 &&
                stream1.getVideoTracks()[0].getSettings().displaySurface !== "monitor"
            ) {
                this.$alert("Please choose to share the full screen", "Screen authorization failed", {
                confirmButtonText: "OK",
                type: "error",
                });
                this.device_screen_status = false;
                this.clearAllResource();
                return;
            }
            this.device_screen_status = true;
            this.screenStream = stream1;
            this.screenVideo.srcObject = stream1;
            this.screenVideo.play();
            this.status = true;
            loading.close();
        },
        close(){
            window.close()
        },
        closeVideo(){
            this.$refs.video.pause()
        },
        getUserNames(){
            // getUserNames(getProjectCode()).then(res=>{
            //     if(res.data.code===0){
            //         this.userNamesMap = res.data.data
            //     }
            // })
        },
        getResidueTime(){


            // console.log(1111,this.event_name,this.videoTime)

            getResidueTime(getProjectCode(),getUserId(),this.event_name,this.videoTime).then(res=>{
                if(res.data.code===0){


                    this.handleRestTime(res.data.data)

                    this.itemData = res.data.data
                }else{
                    window.close()
                }
            })



        },

        getFormatDuringTime(during) {
            var s = Math.floor(during / 1) % 60;
            during = Math.floor(during / 60);
            var i = during % 60;
            during = Math.floor(during / 60);
            var h = during;
            return  h + ':' + i + ':' + s ;
        },
        handleRestTime(item){
            let totalTime = item.have_time
            item.countDownThread = window.setInterval(() => {
                this.$set(item,'countDownTimes',this.getFormatDuringTime(totalTime))
                totalTime--
                if (totalTime < 0) {
                    window.close()
                }
                console.log(this.event_rooms)
            }, 1000)
        },
        invigilateCallback() {},
        refreshVisible() {
        },
        startCheckOnline() {
            this.timer = setInterval(() => {
                if (!this.cameraStream || !this.cameraStream.active) {
                this.device_camera_status = false;
                if (this.status) {
                    this.clearAllResource();
                }
                this.status = false;
                }
                if (!this.screenStream || !this.screenStream.active) {
                this.device_screen_status = false;
                if (this.status) {
                    this.clearAllResource();
                }
                this.status = false;
                }
            }, 1000);
        },
        clearIdStream() {
        if (this.idStream && this.idStream.getTracks) {
            this.idStream.getTracks().forEach((track) => track.stop());
            this.idStream = null;
        }
        this.idVideoStream = null;
        },
        clearAllResource() {
        this.clearIdStream();
        if (this.cameraStream && this.cameraStream.getTracks) {
            this.cameraStream.getTracks().forEach((track) => track.stop());
        }
        if (this.screenStream && this.screenStream.getTracks) {
            this.screenStream.getTracks().forEach((track) => track.stop());
        }
        this.cameraVideo = null;
        this.screenVideo = null;
        this.cameraStream = null;
        this.screenStream = null;
        this.status = false;
        },

    }
}
</script>

<style>
video::-webkit-media-controls-timeline {
  display: none;
}
</style>

<style lang="less">



.screenContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    // align-items: center;
    // justify-content: center;
    overflow: hidden;

    .invigilateStyle {
        position: absolute;

        display: flex;
        top: 10px;
        left: 10px;

        .tag{
            color: white;
            border: 2px solid white;
        }
    }

    .time{
        position: absolute;
        margin-top: 20px;
        width: 80vw;
        margin-left: 10vw;
        height: 50px;
        // background-color: red;
        line-height: 50px;
        color: white;
        font-size: 30px;
        text-align: center;
        font-weight: 900;
    }
    .backImg{
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
    .videoClass{
        position: absolute;
        margin-left: 10%;
        margin-top: 5%;
        height: 80%;
        width: 80%;
    }
    .youtubeClass{
      position: absolute;
      width: 100%;
      padding: 5% 10%;
      box-sizing: border-box;
    }
    .close{
        position: absolute;
        margin-left: 10px;
        width: 100px;
        height: 100px;
        background-color: rebeccapurple;
    }

}
.qnrtc-stream-player {
  width: 100% !important;
  /* height: 1080px !important; */
  background: #000;
}

</style>
